import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Latest projects`}</h2>
    <ProjectCard title="Sistrack.net" link="https://www.sistrack.net" bg="linear-gradient(to right, #4414d4 0%, #170d36 100%)" mdxType="ProjectCard">
  Software for shipping companies, Organize and control everyday process, offered as a SaaS.
    </ProjectCard>
    <ProjectCard title="Consultok.com" link="https://www.consultok.com" bg="linear-gradient(to right, #000000 90%, #febb37 100%)" mdxType="ProjectCard">
  Expert Network Platform, social impact project, featured in Forbes.com
    </ProjectCard>
    <ProjectCard title="Vulcanopro.com" link="https://www.vulcanopro.com" bg="linear-gradient(to right, #ea635d 60%, #FCEE21 100%)" mdxType="ProjectCard">
  Ecosystem to handle Tours and Activities, offered as a SaaS and ecommerce for Latam.
    </ProjectCard>
    <ProjectCard title="iAmalink.com" link="https://www.iamalink.com" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Create a superlink to answer questions through videos messages.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      